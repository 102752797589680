import { TableCell, TableRow } from '@mui/material';

import currency from 'currency.js';

export default function Cotisations({
    cotisationsVars
}) {
    const rows = [
        { id: 5, intitule: 'Urssaf déplafonnée', cotisationsPS: cotisationsVars.h10, cotisationsPP: cotisationsVars.i10 },
        { id: 6, intitule: 'Urssaf plafonnée', cotisationsPS: cotisationsVars.h11, cotisationsPP: cotisationsVars.i11 },
        { id: 7, intitule: 'Alloc familiales (complément)', cotisationsPS: null, cotisationsPP: cotisationsVars.i12 },
        { id: 8, intitule: 'Maladie (complément)', cotisationsPS: null, cotisationsPP: cotisationsVars.i13 },
        { id: 9, intitule: 'CSG', cotisationsPS: cotisationsVars.h14, cotisationsPP: null },
        { id: 10, intitule: 'CSG non déd + CRDS', cotisationsPS: cotisationsVars.h15, cotisationsPP: null },
        { id: 11, intitule: 'Exonération cotisations sur HS', cotisationsPS: cotisationsVars.h16, cotisationsPP: null },
        { id: 12, intitule: 'Exonèreation patronales HS', cotisationsPS: null, cotisationsPP: cotisationsVars.i17 },
        { id: 13, intitule: 'Chômage', cotisationsPS: null, cotisationsPP: cotisationsVars.i18 },
        { id: 14, intitule: 'Retraite Tranche 1', cotisationsPS: cotisationsVars.h19, cotisationsPP: cotisationsVars.i19 },
        { id: 15, intitule: 'Retraite Tranche 2', cotisationsPS: cotisationsVars.h20, cotisationsPP: cotisationsVars.i20 },
        { id: 16, intitule: 'CET', cotisationsPS: cotisationsVars.h21, cotisationsPP: cotisationsVars.i21 },
        { id: 17, intitule: 'Apec TA', cotisationsPS: cotisationsVars.h22, cotisationsPP: cotisationsVars.i22 },
        { id: 18, intitule: 'Apec TB', cotisationsPS: cotisationsVars.h23, cotisationsPP: cotisationsVars.i23 },
        { id: 19, intitule: 'Prévoyance Tranche A', cotisationsPS: null, cotisationsPP: cotisationsVars.i24 },
        { id: 20, intitule: 'Prévoyance Tranche B', cotisationsPS: cotisationsVars.h25, cotisationsPP: cotisationsVars.i25 },
        { id: 21, intitule: 'Mutuelle', cotisationsPS: cotisationsVars.h26, cotisationsPP: cotisationsVars.i26 },
        { id: 22, intitule: 'Formation professionnelle', cotisationsPS: null, cotisationsPP: cotisationsVars.i27 },
        { id: 23, intitule: 'Taxe d\'apprentissage', cotisationsPS: null, cotisationsPP: cotisationsVars.i28 },
        { id: 24, intitule: 'Paritarisme', cotisationsPS: null, cotisationsPP: cotisationsVars.i29 },
    ];

    return (
        <>
            <TableRow>
                <TableCell colSpan={3} style={{ width: 100 }} align="right">Cotisations PS</TableCell>
                <TableCell style={{ width: 100 }} align="right">Cotisation PP</TableCell>
            </TableRow>
            {rows.map((row) => (
                <TableRow key={row.id}>
                    <TableCell colSpan={2}>{row.intitule}</TableCell>
                    <TableCell align="right">{currency(row.cotisationsPS, window.currency_opts).format()}</TableCell>
                    <TableCell align="right">{currency(row.cotisationsPP, window.currency_opts).format()}</TableCell>
                </TableRow>
            ))}
            <TableRow>
                <TableCell align='right' colSpan={2} sx={{ fontWeight: 'bold' }}>Total cotisations</TableCell>
                <TableCell align='right'>{currency(cotisationsVars.h30, window.currency_opts).format()}</TableCell>
                <TableCell align="right">{currency(cotisationsVars.i30, window.currency_opts).format()}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={3} align='right'>Net à payer avant PAS</TableCell>
                <TableCell align="right">{currency(cotisationsVars.h32, window.currency_opts).format()}</TableCell>
            </TableRow>
            <TableRow >
                <TableCell colSpan={3} align='right'>Taux PAS du porté</TableCell>
                <TableCell colSpan={3} align="right">{cotisationsVars.h34.toFixed(2)} %</TableCell>
            </TableRow>
            <TableRow >
                <TableCell colSpan={3} align='right'>Net à payer après PAS</TableCell>
                <TableCell colSpan={3} align="right">{currency(cotisationsVars.h36, window.currency_opts).format()}</TableCell>
            </TableRow>
            <TableRow >
                <TableCell colSpan={3} align='right'>Indemnité de RC</TableCell>
                <TableCell align="right">{currency(cotisationsVars.h38, window.currency_opts).format()}</TableCell>
            </TableRow>
            <TableRow >
                <TableCell colSpan={3} align='right'>Coût global RC</TableCell>
                <TableCell align="right">{currency(cotisationsVars.h39, window.currency_opts).format()}</TableCell>
            </TableRow>
            <TableRow >
                <TableCell colSpan={3} align='right'>Coût global pour la société hors frais de gestion </TableCell>
                <TableCell align="right">{currency(cotisationsVars.i41, window.currency_opts).format()}</TableCell>
            </TableRow>
            <TableRow >
                <TableCell colSpan={3} align='right'>Coût global CA</TableCell>
                <TableCell align="right">{currency(cotisationsVars.i43, window.currency_opts).format()}</TableCell>
            </TableRow>
            <TableRow >
                <TableCell colSpan={3} align='right'>Coût global en paie</TableCell>
                <TableCell align="right">{currency(cotisationsVars.i45, window.currency_opts).format()}</TableCell>
            </TableRow>
        </>
    )
}
