import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './styles/index.css';

const root = ReactDOM.createRoot(
    document.querySelector('body > div') as HTMLElement
);
root.render(
    <App />
);