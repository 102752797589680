import { createContext, useContext, useEffect, useState } from 'react';
import { callApi } from '../utils/request';

export const SimulatorContext = createContext();

export const SimulatorProvider = ({ children }) => {
    const inputsInitial = {
        b6: 0,
        b7: 0,
        b22: 0,
        b10: 0.06,
        b13: 0,
        c26: 0,
        h34: 0,
        g3: 0,
    }

    const [result, setResult] = useState(null);
    const [initial, setInitial] = useState(false);

    const [inputs, setInputs] = useState(inputsInitial);
    const [errorsSimulator, setErrorsSimulator] = useState({})
    const [frais, setFrais] = useState([]);
    const [alignment, setAlignment] = useState('fraisProFixes');

    function checkSimulation(tjm = false) {
        const newErrors = { ...errorsSimulator };
        let hasError = false;

        if (!tjm) {
            if (inputs.b6 === undefined || inputs.b6 <= 0) {
                newErrors.b6 = "Le taux journalier doit être un nombre positif.";
                hasError = true;
            }
            else {
                newErrors.b6 = "";
            }
        } else {
            if (inputs.b8 === undefined || inputs.b8 <= 0) {
                newErrors.b8 = "Le coût global doit être un nombre positif.";
                hasError = true;
            }
            else {
                newErrors.b6 = "";
            }
        }
        if (inputs.b7 === undefined || inputs.b7 <= 0) {
            newErrors.b7 = "Le nombre de jours doit être un entier positif.";
            hasError = true;
        }
        else {
            newErrors.b7 = "";
        }

        if (inputs.b10 === undefined || inputs.b10 < 0) {
            newErrors.b10 = "Les frais de gestion doivent être un nombre positif.";
            hasError = true;
        }
        else {
            newErrors.b10 = "";
        }

        if (inputs.b13 === undefined || inputs.b13 < 0) {
            newErrors.b13 = "Les frais professionnel doit être un nombre positif.";
            hasError = true;
        }
        else {
            newErrors.b13 = "";
        }

        if (inputs.h34 === undefined || inputs.h34 < 0) {
            newErrors.h34 = "Le taux du prélèvement à la source doivent être un nombre positif.";
            hasError = true;
        }
        else {
            newErrors.h34 = "";
        }

        if (inputs.g3 === undefined || inputs.g3 < 0) {
            newErrors.g3 = "Le nombre d'heures supplémentaires doit être un nombre positif.";
            hasError = true;
        }
        else {
            newErrors.g3 = "";
        }

        setErrorsSimulator(newErrors);
        return hasError;
    }

    function Prospect() {
        // TODO : Récupérer info user
    }

    function prospectSimulation(id, initial) {
        callApi('/operateur/prospectSimulationById', 'POST', { id: id, initial: initial }).then((res) => {
            setResult(res);
            setInputs({
                b6: res.b6,
                b7: res.b7,
                b8: res.b8,
                b10: res.b10,
                b13: res.b13,
                c26: res.c26,
                h34: res.h34,
                g3: res.g3
            });
        })
    }

    const updateInput = (field, value) => {
        setInputs(prevInputs => ({
            ...prevInputs,
            [field]: parseFloat(value) || 0
        }));
        setInitial(false);
    };

    return (
        <SimulatorContext.Provider value={{
            result, frais, setFrais, alignment, setAlignment, setResult, setFrais, inputs, setInputs, inputsInitial, checkSimulation, updateInput,
            initial, setInitial, prospectSimulation, errorsSimulator, setErrorsSimulator
        }}>
            {children}
        </SimulatorContext.Provider>
    );
};

export const useSimulator = () => useContext(SimulatorContext);
