import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

export default function RequireAuth({ children, authRole, multiplex }) {
    const { isAuthenticated, userRole } = useAuth();
    const location = useLocation();

    const notfound = "/notfound";

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (multiplex) {
        return multiplex[userRole] ? multiplex[userRole] : <Navigate to={notfound} state={{ from: location }} replace />;
    } else if (authRole) {
        if (!authRole.includes(userRole)) {
            return <Navigate to={notfound} state={{ from: location }} replace />;
        }
    }

    return children;
}
