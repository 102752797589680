import React, { useEffect, useState } from 'react';
import {
    Typography,
    Switch,
    Card,
    Box,
    Alert,
    Button
} from '@mui/material';

import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { callApi } from '../../utils/request';
import InformationsFrais from '../../components/InformationsFrais';
import { useSimulator } from '../../contexts/SimulatorContext';
import ContactFormFields from '../../components/ContactFormField';

import currency from 'currency.js';

import './CreateSimulation.scss'

export default function CreateSimulation() {
    const [simulation, setSimulation] = useState();
    const [tjm, setTjm] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(false);
    const [contactInfo, setContactInfo] = useState(null);
    const { inputs, setInputs, inputsInitial, errorsSimulator, checkSimulation } = useSimulator();
    const initialUser = {
        nom: '',
        prenom: '',
        mail: '',
        phone: '',
    };

    const [paramsProspect, setParamsProspect] = useState(initialUser);
    const [errorsIdentity, setErrorsIdentity] = useState({ mail: '', phone: '' });

    const checkErrors = () => {
        let hasError = false;
        let newErrors = { ...errorsIdentity };

        if (!paramsProspect.mail) {
            newErrors.mail = 'Email est requis';
            hasError = true;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(paramsProspect.mail)) {
            newErrors.mail = 'Email invalide';
            hasError = true;
        } else {
            newErrors.mail = '';
        }

        if (!paramsProspect.phone) {
            newErrors.phone = 'Numéro de téléphone est requis';
            hasError = true;
        } else if (!/^\d{10}$/.test(paramsProspect.phone)) {
            newErrors.phone = 'Numéro de téléphone invalide';
            hasError = true;
        } else {
            newErrors.phone = '';
        }

        setErrorsIdentity(newErrors);
        return hasError;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSimulation(null);
        
        const errors = checkSimulation(tjm);
        const errorsProspect = checkErrors();
        
        if (!errors && !errorsProspect) {
            setLoading(true);
            const paramsToSend = {
                tauxJournalier: tjm ? 0 : parseFloat(inputs.b6) || 0,
                netAPayer: tjm ? parseFloat(inputs.b8) || 0 : 0,
                nbJours: parseFloat(inputs.b7) || 0,
                fraisGestion: parseFloat(inputs.b10) || 0,
                fraisProfessionnel: parseFloat(inputs.b13) || 0,
                fraisPAS: parseFloat(inputs.h34) || 0,
                mutuelle: parseFloat(inputs.c26) || 0,
                heures_sup: parseFloat(inputs.g3) || 0
            };

            callApi('/operateur/createSimulationProspect', 'POST', { simulation: paramsToSend, prospect: paramsProspect, tjm: tjm }).then((res) => {
                setLoading(false);
                setSimulation(res);
            });
        }
    };


    const handleChange = (event) => {
        setTjm(event.target.checked);
        setSimulation(null);
    };

    useEffect(() => {
        setInputs(inputsInitial);
    }, [tjm]);


    return (
        <div className='contentAnonyme'>
            <Header name="Création d'une simulation" />

            <Card>
                <Typography variant="h5" align="center" sx={{ padding: 2 }}>
                    Information du contact
                </Typography>
                <ContactFormFields paramsProspect={paramsProspect} setParamsProspect={setParamsProspect}
                    errorsIdentity={errorsIdentity} />
            </Card>
            <Box className='switchContainer'>
                <Typography
                    variant="h6"
                    align="center"
                    className={`switchLabel ${!tjm && 'switchLabelActive'}`}
                >
                    TJM
                </Typography>
                <Switch
                    onChange={handleChange}
                    color="default"
                    checked={tjm}
                />
                <Typography
                    variant="h6"
                    align="center"
                    className={`switchLabel ${tjm && 'switchLabelActive'}`}
                >
                    Chiffre d'affaires
                </Typography>
            </Box>
            {alert && <Alert severity={alert.variant}> {alert.message} </Alert>}
            <div className='contentSimulator'>
                <Card sx={{ minWidth: 275 }} className='left'>
                    <InformationsFrais tjm={tjm} />
                    <div className='button-container' style={{textAlign:'center'}}>
                        <Button
                            className='buttonSimulation'
                            variant="outlined"
                            onClick={handleSubmit}
                        >
                            Simuler
                        </Button>
                    </div>
                </Card>
                <Card className='simulator'>
                    <Typography variant="h5" align="center">
                        Estimation
                    </Typography>
                    <div className='bodyEstimation'>
                        {simulation ?
                            tjm ? (
                                <div>
                                    <div>Taux journalier moyen : {currency(simulation["b6"], window.currency_opts).format()}</div>
                                    <Button variant="outlined" color="warning" sx={{ marginTop: '20px' }}>
                                        <Link to={`/dashboard/Simulateur/${simulation["generatedId"]}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Voir plus de détails
                                        </Link>
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <div>Net avant impôt {simulation["h32"]} €/mois</div>
                                    <Button variant="outlined" color="warning" sx={{ marginTop: '20px' }}>
                                        <Link to={`/dashboard/Simulateur/${simulation["generatedId"]}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Voir plus de détails
                                        </Link>
                                    </Button>
                                </div>
                            )
                            :
                            <>
                                {loading
                                ? (<>
                                    <img src='/loading.svg' alt='loading' style={{width:70}}/>
                                    <p style={{marginLeft:6}}>
                                        Calculs en cours...<br />
                                        <span style={{fontSize:"smaller"}}>Veuillez patienter</span>
                                    </p>
                                </>)
                                : <>
                                    <p style={{fontSize:"smaller"}}>(le résultat sera présenté ici)</p>
                                </>
                                }
                            </>
                        }
                    </div>
                </Card>
            </div >
        </div >
    );
}
