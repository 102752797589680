import { TableBody, TableCell, TableContainer, Paper, TableRow, Table } from '@mui/material';
import Cotisations from '../components/Cotisations';

import currency from 'currency.js';

import './PaySlip.scss'

export default function PaySlip({ payslipVars }) {
    return (
        <TableContainer className="payslip" component={Paper}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Salaire de base</TableCell>
                        {/* Salaire brute de base. */}
                        <TableCell align='right'>
                            {currency(payslipVars.g2, window.currency_opts).format()}
                        </TableCell>

                        {/* Taux ? */}
                        <TableCell align='right'>
                            {payslipVars.h2.toFixed(2).replace('.', ',')}
                        </TableCell>

                        {/* Salaire brut de base multiplité par le taux. */}
                        <TableCell align='right'>
                            {currency(payslipVars.i2, window.currency_opts).format()}
                        </TableCell>
                    </TableRow>

                    {/* Heures supplémentaires */}
                    {payslipVars.g3 ?
                        <TableRow>
                            <TableCell>Heures supplémentaires</TableCell>
                            <TableCell align='right'>
                                {/* Nombre d'heure supp. */}
                                {payslipVars.g3.toFixed(2).replace('.', ',')}
                            </TableCell>

                            <TableCell align='right'>
                                {/* Mutiplication de h2 par 1,25. */}
                                {payslipVars.h3.toFixed(2).replace('.', ',')}
                            </TableCell>

                            <TableCell align='right'>
                                {/* Multiplication de g3 par h3. */}
                                {currency(payslipVars.i3, window.currency_opts).format()}
                            </TableCell>
                        </TableRow> : null
                    }

                    <TableRow>
                        <TableCell colSpan={3}>Indemnité apport d'affaires 5 %</TableCell>
                        <TableCell align="right">{payslipVars.i4.toFixed(2).replace('.', ',')} %</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>Constitution de la réserve financière 10 %</TableCell>
                        <TableCell align="right">{payslipVars.i5.toFixed(2).replace('.', ',')} %</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={3}>Indemnité de congés payés 10 %</TableCell>
                        <TableCell align="right">{payslipVars.i6.toFixed(2).replace('.', ',')} %</TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell colSpan={3} align="right" sx={{ fontWeight: 'bold' }}>Total brut</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>{currency(payslipVars.i7, window.currency_opts).format()}</TableCell>
                    </TableRow>

                    <Cotisations cotisationsVars={payslipVars} />
                </TableBody>
            </Table>
        </TableContainer>
    )
}

