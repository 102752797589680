import axios from 'axios';
const mainApi = process.env.REACT_APP_API_URL;

export const callApi = (url, method, data) => {
    return new Promise(async (resolve, reject) => {
        const headers = { 'Authorization': localStorage.getItem('token') || null };
        const result = await axios({
            url: mainApi + url,
            headers: headers,
            method: method,
            data: data
        }).then((res) => {
            return resolve(res.data);
        }).catch((err) => {
            return reject({ errorStatus: err.response?.status });
        })
        return reject(result);
    })
}