import { useState } from 'react';
import {
    Container,
    Box,
    Alert,
    Checkbox,
    Typography,
    TextField
} from '@mui/material';

import {
    GridFooter,
    DataGrid,
    GridToolbarContainer,
    GridFooterContainer,
} from '@mui/x-data-grid';

import Header from '../../components/Header';


const columns = [
    { field: 'name', headerName: 'Nom', width: 250 },
    { field: 'value', headerName: 'Valeur', width: 250, renderCell: params => <TextField size='small' sx={{ verticalAlign: 'middle', fontSize: 10 }} value={params.value} /> },
    {
        field: 'anoView', headerName: 'Vue Anonyme', width: 150, renderCell: (params) => (

            <Checkbox
                checked={params.row.anoView}
            // onChange={(event) => handleCheckboxChange(event, params.row.id)}
            />
        ),
    },
    {
        field: 'anoEdit', headerName: 'Edit Anonyme', width: 150, renderCell: (params) => (

            <Checkbox
                checked={params.row.anoEdit}
            // onChange={(event) => handleCheckboxChange(event, params.row.id)}
            />
        ),
    },
    {
        field: 'opeView', headerName: 'Vue Opérateur', width: 150, renderCell: (params) => (

            <Checkbox
                checked={params.row.opeView}
            // onChange={(event) => handleCheckboxChange(event, params.row.id)}
            />
        ),
    },
    {
        field: 'opeEdit', headerName: 'Edit Opérateur', width: 150, renderCell: (params) => (

            <Checkbox
                checked={params.row.opeEdit}
            // onChange={(event) => handleCheckboxChange(event, params.row.id)}
            />
        ),
    }

];

const rows = [
    { id: 1, name: "SMIC 2021", value: 11.52 },
    { id: 2, name: "PMSS", value: 3666 },
];
export default function ListVariables() {
    const [alert, setAlert] = useState(null);


    return (
        <Container sx={{ padding: 5 }}>
            <Typography variant="h4" align='center'>Liste des variables</Typography>
            <Box sx={{ height: 400, width: '100%' }}>
                {alert && <Alert severity={alert.variant}> {alert.message} </Alert>}
                <DataGrid
                    //   onRowDoubleClick={(e) => console.log(e[0])}
                    //   onRowSelectionModelChange={(rowSelect) => setSelectedRows(rowSelect[0])}
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[10]}
                    disableRowSelectionOnClick
                    sx={{ backgroundColor: 'white' }}
                //   slots={{
                //     toolbar: EditToolbar, footer: FooterToolbar
                //   }}
                />
            </Box>
        </Container>
    )
}
