import React, { useEffect, useState } from 'react';
import { TableRow, TableCell, FormControl, Select, MenuItem, OutlinedInput, InputAdornment, Button } from '@mui/material';
import './TypeFrais.scss';

export default function TypeFrais({ frais, setFrais, vars }) {
    const [localFrais, setLocalFrais] = useState(frais);
    useEffect(() => {
        if (vars) {
            setLocalFrais(vars);
        } else {
            setLocalFrais([{ type: '', valeur: '' }]);
        }
    }, []);

    useEffect(() => {
        setFrais(localFrais);
    }, [localFrais, setFrais]);

    const handleAddFrais = () => {
        setLocalFrais(prevFrais => [...prevFrais, { type: '', valeur: '' }]);
    };

    const handleTypeChange = (index, event) => {
        const newFrais = localFrais.map((item, i) =>
            i === index ? { ...item, type: event.target.value } : item
        );
        setLocalFrais(newFrais);
    };

    const handleValeurChange = (index, event) => {
        const newFrais = localFrais.map((item, i) =>
            i === index ? { ...item, valeur: event.target.value } : item
        );
        setLocalFrais(newFrais);
    };

    const handleAutreTypeChange = (index, event) => {
        const newFrais = localFrais.map((item, i) =>
            i === index ? { ...item, autreType: event.target.value } : item
        );
        setLocalFrais(newFrais);
    };

    return (
        <>
            {localFrais.map((fraisItem, index) => (
                <TableRow key={index} className='editableRow'>
                    <TableCell>
                        <FormControl size="small" sx={{ width: 200 }}>
                            <Select
                                value={fraisItem.type}
                                onChange={(event) => handleTypeChange(index, event)}
                                displayEmpty
                                sx={{ width: '100%', fontSize:14 }}
                            >
                                <MenuItem value="">Sélectionner un type</MenuItem>
                                <MenuItem value="essence">Essence</MenuItem>
                                <MenuItem value="téléphone">Téléphone</MenuItem>
                                <MenuItem value="ligne internet">Ligne Internet</MenuItem>
                                <MenuItem value="frais kilométrique">Frais Kilométrique</MenuItem>
                                <MenuItem value="autre">Autre</MenuItem>
                            </Select>
                        </FormControl>

                        {fraisItem.type === 'autre' && (
                            <div className='autreTypeWrapper'>
                                <OutlinedInput
                                    size='small'
                                    placeholder="Autre type"
                                    value={fraisItem.autreType || ''}
                                    onChange={(event) => handleAutreTypeChange(index, event)}
                                    sx={{ width: 200, marginLeft: 1, fontSize:14 }}
                                />
                            </div>
                        )}
                    </TableCell>

                    <TableCell align="right">
                        <OutlinedInput
                            size='small'
                            placeholder="Valeur"
                            value={fraisItem.valeur || ''}
                            onChange={(event) => handleValeurChange(index, event)}
                            sx={{ width: 200, fontSize:14 }}
                            startAdornment={<InputAdornment position="start">€</InputAdornment>}
                        />
                    </TableCell>
                </TableRow>
            ))}

            <TableRow>
                <TableCell colSpan={2} align="center">
                    <Button variant="outlined" onClick={handleAddFrais}>Ajouter un type de frais</Button>
                </TableCell>
            </TableRow>
        </>
    );
}
