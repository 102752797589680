import { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Button,
  Typography,
  Switch,
  FormControlLabel,
  Grid
} from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { callApi } from '../../utils/request';
import { useSimulator } from '../../contexts/SimulatorContext';

import currency from 'currency.js';

const columns = [
  { field: 'prenom', headerName: 'Prénom', width: 200 },
  { field: 'nom', headerName: 'Nom', width: 200 },
  { field: 'mail', headerName: 'Adresse mail', width: 300 },
  { field: 'phone', headerName: 'Téléphone', width: 200 },
  { field: 'taux_journalier_curr', headerName: 'Taux Journalier', width: 200, valueFormatter:v => currency(v, window.currency_opts).format() },
  { field: 'nb_jours_curr', headerName: 'Nombre de jours', width: 200 },
  { field: 'frais_professionnel_curr', headerName: 'Frais Professionnel', width: 200, valueFormatter:v => currency(v, window.currency_opts).format() },
  { field: 'frais_gestion_curr', headerName: 'Frais de gestion', width: 200, valueFormatter: v => `${(v * 100).toFixed(2).replace('.', ',')} %` },
  { field: 'source', headerName: 'Source', width: 200, valueFormatter: v => v ? "Externe" : "Interne" },
  {
    field: 'state',
    headerName: 'État',
    minWidth: 200,
    maxWidth: 200,
    renderCell: (params) => {
      let color, name;
      switch (params.value) {
        case 0:
          color = 'warning';
          name = 'En cours';
          break;
        case 1:
          color = 'success';
          name = 'Terminé';
          break;
        default:
          color = 'default';
      }
      return <Chip label={name} color={color} />;
    }
  }
];

export default function ListSimulation() {
  const [prospectSimulations, setProspectSimulations] = useState([]);
  const [hasCloture, setHasCloture] = useState(true);
  const navigate = useNavigate();
  const { setResult } = useSimulator();

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid>
            <Button
              color="warning"
              startIcon={<AddIcon />}
              onClick={() => navigate('/dashboard/CreateSimulation')}
            >
              Création d'une simulation
            </Button>
          </Grid>
          <Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <GridToolbarQuickFilter
                  variant="outlined"
                  placeholder="Rechercher..."
                  size="small"
                  color='warning'
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hasCloture}
                      color="warning"
                      onChange={() => setHasCloture(!hasCloture)}
                    />
                  }
                  label="Afficher les clôturées"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    callApi('/operateur/prospectSimulations', 'GET').then((res) => {
      const filteredSimulations = hasCloture ? res : res.filter(simulation => simulation.state === 0);
      setProspectSimulations(filteredSimulations);
    });
    setResult(null);
  }, [hasCloture, setResult]);

  return (
    <>
      <Typography variant="h4" align='center'>Liste des simulations</Typography>
      <Box sx={{ width: '90%', margin: '0 auto', height: '939px' }}>
        <DataGrid
          onRowDoubleClick={(e) => navigate(`/dashboard/Simulateur/${e.id}`)}
          rows={prospectSimulations}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[10]}
          slots={{
            toolbar: EditToolbar
          }}

          sx={{ '--DataGrid-overlayWrapper': '300px' }}
        />
      </Box>
    </>
  );
}
