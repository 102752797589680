import React from 'react';
import {
  Typography,
  Tooltip,
  IconButton,
  Box
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export default function Header({ name }) {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
      <Tooltip title="Retour">
        <IconButton onClick={() => navigate(-1)} sx={{ marginRight: 2 }}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="h4">
        {name}
      </Typography>
    </Box>
  );
}
