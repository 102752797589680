import React from 'react';
import { Box, Grid, Typography, Card, CardContent, CardHeader, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; 
import ListOperateur from './ListOperateur';
import ListVariables from './ListVariables';

const Dashboard = () => {
    const { isAuthenticated, userRole } = useAuth(); 

    return (
        <Box sx={{ flexGrow: 1, padding: 3, margin: '0 50px' }}>
            <ListOperateur />
            <ListVariables />
        </Box>
    );
};

export default Dashboard;
