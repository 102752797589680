import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import AuthenticatedRouter from './routers/AuthenticatedRouter';
import Navbar from './components/Navbar';
import Login from './views/Login';
import './styles/App.scss';
import RequireAuth from './contexts/RequireAuth';

/**
 * currency
 *
 * Package de formatage de devices monétaires.
 *
 * @const currency_opts   défini les options utilisé lors de l'utilisation
 *                        du package currency.
 * @param obj.separator   indique le séparateur de millier.
 * @param obj.decimal     indique le symbole utilisé pour la séparation des décimales.
 * @param obj.precision   indique la précision.
 * @param obj.symbol      indique le symbole de device à utiliser.
 * @param obj.pattern     indique le pattern à utiliser.
 *                        Le configuration actuelle génèrera: # ! -> 1 123,45 EUR
*/
window.currency_opts = {
  separator:" ",
  decimal:",",
  precision:2,
  symbol:"€",
  pattern: "# !"
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route path='/' element={
            <RequireAuth multiplex={{
                "admin": <Navigate to='/dashboard/' />,
                "operateur": <Navigate to='/dashboard/ListSimulation' />
              }}>
            </RequireAuth>
          } />

          <Route path='/login' element={<Login />} />
          <Route path='/dashboard/*' element={
            <RequireAuth authRole={['admin', 'operateur']}>
              <AuthenticatedRouter />
            </RequireAuth>
          } />

          <Route path='*' element={<h1>Not found</h1>} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
