import { useEffect, useState } from 'react';
import { Box, TextField, Typography, Button, Alert } from "@mui/material";

import { useAuth } from '../contexts/AuthContext';
import { callApi } from '../utils/request';
import { useNavigate } from "react-router-dom";


import Logo from '../logo_bsl.png';

export default function FormLogin() {
    const [alert, setAlert] = useState(null);
    const [codeSend, setCodeSent] = useState(false);
    const [userInformations, setUserInformations] = useState({});
    const navigate = useNavigate();

    const { login, isAuthenticated, userRole } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            if (userRole === 'admin') {
                navigate('/dashboard/admin');
            } else {
                navigate('/dashboard/ListSimulation');
            }
        }
    }, [isAuthenticated]);

    const handleChange = (event) => {
        setUserInformations({ ...userInformations, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!codeSend) {
            callApi('/auth/get-code', 'POST', userInformations).then((res) => {
                if (res.alert) {
                    setAlert(res.alert);
                    if (res.alert.variant === 'success') {
                        setCodeSent(true);
                    }
                }
            });
        } else {
            callApi('/auth/login', 'POST', userInformations).then((res) => {
                if (res.token) {
                    login(res.token);
                }
            });
        }
    };

    return (
        <Box className="form">
            <img src={Logo} alt="logo" />
            <Typography component="h1" variant="h5">
                Connexion
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="mail"
                    label="Email"
                    name="mail"
                    type='email'
                    autoComplete="email"
                    autoFocus
                    onChange={handleChange}
                />
                {codeSend &&
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="otpCode"
                        label="Code OTP"
                        type="text"
                        id="otpCode"
                        onChange={handleChange}
                    />}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 3 }}
                >
                    {codeSend ? 'Connexion' : 'Envoyer le code OTP'}
                </Button>
            </Box>
            {alert && <Alert severity={alert.variant}> {alert.message} </Alert>}
        </Box>
    );
}
