import { Routes, Route, Navigate } from 'react-router-dom';
import CreateOperateur from '../views/admin/CreateOperateur';
import Dashboard from '../views/admin/Dashboard';
import ListSimulation from '../views/operateur/ListSimulation';
import RequireAuth from '../contexts/RequireAuth';
import CreateSimulation from '../views/operateur/CreateSimulation';
import Simulateur from '../views/Simulateur';
import { SimulatorProvider } from '../contexts/SimulatorContext';

export default function AuthenticatedRouter() {
    return (
        <>
            <Routes>
                {/* Page par défaut. */}
                {/* Admin: Ouverture du Dashboard. */}
                {/* Opérateur : Redirection vers la list des simulation, page par défaut. */}
                <Route index element={
                    <RequireAuth multiplex={{
                        "admin": <Dashboard />,
                        "operateur": <Navigate to='ListSimulation' />
                    }}>
                    </RequireAuth>
                } />


                {/* <Route index element={<Dashboard />} /> */}
                <Route path='/createOperateur' element={
                    <RequireAuth authRole={['admin']}>
                        <CreateOperateur />
                    </RequireAuth>
                } />
            </Routes>
            <SimulatorProvider>
                <Routes>
                    {/* Page : liste des simulations. */}
                    <Route path='/ListSimulation' element={
                        <RequireAuth authRole={['admin', 'operateur']}>
                            <ListSimulation />
                        </RequireAuth>
                    } />

                    {/* Page : création d'une simulation. */}
                    <Route path='/CreateSimulation' element={
                        <RequireAuth authRole={['admin', 'operateur']}>
                            <CreateSimulation />
                        </RequireAuth>
                    } />

                    {/* Page : une simulation choisie. */}
                    <Route path='/Simulateur/:id' element={
                        <RequireAuth authRole={['admin', 'operateur']}>
                            <Simulateur />
                        </RequireAuth>
                    } />
                </Routes>
            </SimulatorProvider>
        </>
    );
}
