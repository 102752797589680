// ContactFormFields.js

import React from 'react';
import { TextField, TableBody, TableCell, TableRow, Table } from '@mui/material';

export default function ContactFormFields({ paramsProspect, setParamsProspect, errorsIdentity, disabled }) {
    const handleChange = (event) => {
        setParamsProspect({ ...paramsProspect, [event.target.name]: event.target.value });
    };

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell align='right'>
                        <TextField
                            fullWidth
                            size="small"
                            name="prenom"
                            label="Prénom"
                            value={paramsProspect.prenom}
                            onChange={handleChange}
                            error={!!errorsIdentity.prenom}
                            helperText={errorsIdentity.prenom}
                            disabled={disabled}
                        />
                    </TableCell>
                    <TableCell align='right'>
                        <TextField
                            label="Nom"
                            fullWidth
                            size="small"
                            name="nom"
                            value={paramsProspect.nom}
                            onChange={handleChange}
                            error={!!errorsIdentity.nom}
                            helperText={errorsIdentity.nom}
                            disabled={disabled}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4} align='right'>
                        <TextField
                            required
                            fullWidth
                            label="Adresse email"
                            size="small"
                            name="mail"
                            value={paramsProspect.mail}
                            onChange={handleChange}
                            error={!!errorsIdentity.mail}
                            helperText={errorsIdentity.mail}
                            disabled={disabled}
                        />
                    </TableCell>
                </TableRow>
                <TableRow >
                    <TableCell colSpan={4} align='right'>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            name="phone"
                            label="Téléphone"
                            value={paramsProspect.phone}
                            onChange={handleChange}
                            error={!!errorsIdentity.phone}
                            helperText={errorsIdentity.phone}
                            disabled={disabled}
                        />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}
