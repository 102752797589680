import { Container } from "@mui/material";

import '../styles/form.scss';
import FormLogin from "../components/FormLogin";
export default function Login() {
    return (
        <Container>
            <FormLogin />
        </Container>
    )
}
