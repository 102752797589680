import { useEffect, useState } from 'react';
import {
  Container,
  Button,
  Box,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography

} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {
  GridFooter,
  DataGrid,
  GridToolbarContainer,
  GridFooterContainer,
} from '@mui/x-data-grid';

import { useNavigate } from 'react-router-dom';
import { callApi } from '../../utils/request';

import Header from '../../components/Header';

const columns = [
  { field: 'id', resizable:false, headerName: 'ID', width: 100, valueFormatter:v => v.substring(0, 8)  },
  { field: 'prenom', resizable:true, headerName: 'Prénom', width: 220 },
  { field: 'nom', resizable:true, headerName: 'Nom', width: 220 },
  { field: 'mail', resizable:true, headerName: 'Adresse mail', width: 300 }

];

export default function ListOperateur() {
  const [selectedRows, setSelectedRows] = useState(null);
  const [operators, setOperators] = useState([]);
  const [alert, setAlert] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    callApi('/admin', 'GET').then((res) => {
      console.log(operators);
      setOperators(res);
    }).catch((error) => {
      console.error('Error fetching operators:', error);
      setAlert({ variant: 'error', message: 'Erreur lors du chargement des opérateurs.' });
    });
  }, [alert]);

  const handleClose = (confirmation) => {
    if (confirmation) {
      deleteOperator(selectedRows);
    }
    setDeleteConfirm(false);
  };

  function deleteOperator(id) {
    console.log('Suppression de l\'opérateur ' + id);
    callApi(`/admin/deleteOperator/${id}`, 'DELETE').then((res) => {
      if (res.alert) {
        setAlert(res.alert);
        if (res.alert.variant === 'success') {
          setTimeout(() => setAlert(null), 2500);
        }
      }
    })
  }

  function EditToolbar() {
    return (
      <GridToolbarContainer>
        <Button color="warning" startIcon={<AddIcon />} onClick={() => navigate('/dashboard/createOperateur')}>
          Ajouter opérateur
        </Button>
      </GridToolbarContainer>
    );
  }

  function FooterToolbar() {
    return (
      <GridFooterContainer sx={{ justifyContent: selectedRows ? 'space-between' : 'end' }}>
        {selectedRows ?
          <Box display="flex" gap={1}>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => setDeleteConfirm(true)}
            >
              Supprimer
            </Button>
          </Box> : null}
        <GridFooter sx={{ border: 'none' }} />
      </GridFooterContainer>
    )
  }

  return (
    <Container>
      <Typography variant="h4" align='center' sx={{ marginBottom: '20px' }}>Liste des opérateurs</Typography>
      <Box sx={{ height: 400, width: '100%' }}>
        {alert && <Alert severity={alert.variant}> {alert.message} </Alert>}
        <DataGrid
          onRowDoubleClick={(e) => console.log(e[0])}
          onRowSelectionModelChange={(rowSelect) => setSelectedRows(rowSelect[0])}
          rows={operators}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          sx={{backgroundColor:'white'}}
          slots={{
            toolbar: EditToolbar, footer: FooterToolbar
          }}
        />
      </Box>
      <Dialog
        open={deleteConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Suppression d'un opérateur
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de bien vouloir supprimer cet opérateur ? Cette action est irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Annuler</Button>
          <Button onClick={() => handleClose(true)} autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
