import { Link } from 'react-router-dom';
import { AppBar, Toolbar, List, ListItem, ListItemButton } from '@mui/material';

import { useAuth } from '../contexts/AuthContext';

import Logo from '../logo_bsl.png';

import './Navbar.scss';

const navItems = [
    {key:"admin", label:"Admin", location:"/dashboard/", role:"admin"},
    {key:"simu", label:"Simulations", location:"/dashboard/ListSimulation"},
    {key:"logout", label:"Déconnexion", location:"/login"}
];

export default function Navbar() {
    const { isAuthenticated, logout, userRole } = useAuth();

    const actions = {
        logout: logout
    };

    const flexContainer = {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        color:'red'
    };

    return (
        <AppBar className='navBar'>
            <Toolbar style={{ justifyContent: 'space-between', display: 'flex' }}>
                <img src={Logo} alt='BSL Logo' className='logo' />

                {isAuthenticated &&
                    <>
                    <nav>
                        <List style={flexContainer}>
                            {navItems.map((item) => (
                                (!item.role || item.role == userRole)
                                    ?
                                    <ListItem key={item.key} disablePadding>
                                        <ListItemButton sx={{ textAlign:'center', padding:0 }}>
                                            <Link
                                                to={item.location}
                                                onClick={actions[item.key] || ""}
                                                style={{padding:"8px 16px"}}
                                            >
                                                {item.label}
                                            </Link>
                                        </ListItemButton>
                                    </ListItem>
                                    :
                                    <></>
                            ))}
                        </List>
                    </nav>
                    </>
                }
            </Toolbar>
        </AppBar>
    );
};