import { useState } from 'react';
import {
    Container,
    Typography,
    Button,
    Box,
    Grid,
    TextField,
    Alert
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { callApi } from '../../utils/request';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';


export default function CreateOperateur() {
    const navigate = useNavigate();

    const initialState = {
        nom: '',
        prenom: '',
        mail: '',
        role: ''
    }
    const [operateurInformations, setOperateurInformations] = useState(initialState);
    const [alert, setAlert] = useState(null);
    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setOperateurInformations({ ...operateurInformations, [event.target.name]: event.target.value });
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        callApi('/admin/createOperator', 'POST', operateurInformations).then((res) => {
            if (res.alert) {
                setAlert(res.alert);
                if (res.alert.variant === 'success') {
                    setOperateurInformations(initialState);
                    setTimeout(() => setAlert(null), 2500);
                }
            }
        })
    }

    return (
        <Container>
            <Header name="Création d'un opérateur" />
            {alert && <Alert severity={alert.variant}> {alert.message} </Alert>}
            <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit} className="form">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="given-name"
                            name="prenom"
                            required
                            fullWidth
                            id="prenom"
                            label="Prénom"
                            autoFocus
                            onChange={handleChange}
                            value={operateurInformations.prenom}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="nom"
                            label="Nom"
                            name="nom"
                            autoComplete="family-name"
                            onChange={handleChange}
                            value={operateurInformations.nom}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            required
                            fullWidth
                            id="mail"
                            label="Mail"
                            name="mail"
                            autoComplete="email"
                            onChange={handleChange}
                            value={operateurInformations.mail}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Rôle</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={operateurInformations.role}
                                label="role"
                                name='role'
                                onChange={handleChange}
                            >
                                <MenuItem value={1}>Admin</MenuItem>
                                <MenuItem value={0}>Opérateur</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Ajouter
                </Button>

            </Box>
        </Container>
    )
}
